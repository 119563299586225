.terms-and-conditions {
    padding: 20px;
    max-width: 100%;
  }
  
  .contact-us-section {
    margin-top: 50px;
    border-top: 2px solid #ccc;
    padding-top: 20px;
  }
  
  h1 {
    color: #333;
    font-weight: 700;
    font-size: 30px;
  }
  
  h2{
    color: #333;
    font-weight: 700;
    font-size: 20px;
    margin-bottom: 5px;
  }
  h3{
    color: #333;
    font-weight: 500;
    font-size: 18px;
  }
  p {
    color: #666;
    padding-top: 10px;
    padding-bottom: 10px;
  }
  
  ol {
    list-style-type: decimal;
  }
  
  .table-of-contents {
    padding: 0;
  }
  
  .table-of-contents li {
    margin-bottom: 10px;
    list-style-type: none;
  }
  
  .table-of-contents li a {
    color: blue;
    text-decoration: underline;
  }
  section{
    padding: 10px;
    margin: 5px;
  }
  
  section h2{
    padding-bottom: 10px;
  }
  
  .list-style-item{
  list-style-type: disc;
  }
  ul{
  padding-left: 10px;
  }
  
  
  .bolding-letters {
  font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
  font-weight: bold;
  color: rgb(0, 0, 0);
  
  }
  
  .underlining-content{
  text-decoration: underline;
  }