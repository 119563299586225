@tailwind base;
@tailwind components;
@tailwind utilities;

.list {
  display: flex;
  overflow-x: scroll;
  padding: 20px;
  margin-left: 10px;
  margin-right: 10px;

  /* prevent accidentally going back or triggering other swipe events */
  overscroll-behavior: contain;

  /* hide scroll bar */
  scrollbar-width: none;
  -ms-overflow-style: none;

  /* momentum scroll on iOS */
  -webkit-overflow-scrolling: touch;

  /* disbable vertical scroll for touch */
  touch-action: pan-x;

  /* scroll snapping */
  scroll-snap-type: x mandatory;

  /* stop scrolling before reaching edge */
  scroll-padding-left: 20px;
  scroll-padding-inline-start: 20px;
}

/* hide scroll bar */
.list::-webkit-scrollbar {
  display: none;
}

.item {
  width: 3.5rem;
  min-height: 3.3rem;
  flex-shrink: 0;

  /* scroll snapping */
  scroll-snap-align: start;

  /* maybe this will work in the future 🤷‍♂️ */
  scroll-snap-stop: always;
}

/* .item:last-child {
  position: relative;
} */

/* .item:last-child::after {
  position: absolute;
  left: 100%;
  height: 1px;
  width: 20px;
  display: block;
  content: "";
} */

/* 
    The styling below is only for the decoration of this demo, 
    it has no impact on scrolling or scroll behavior
  */
.list {
  list-style: none;
  border: 5px solid #fff;
  /* background-color: #fff; */
  padding: 10px;
  border-radius: 12px;
}

.item {
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  border-radius: 8px;
  font-size: 32px;
  text-shadow: 0 2px 1px rgba(0, 0, 0, 0.4);
  box-shadow: 0 2px 1px rgba(0, 0, 0, 1);
  cursor: pointer;
}

.item:not(:last-child) {
  margin-right: 10px;
}

.viewport {
  max-width: 520px;
  margin-left: 10px;
  margin-right: 10px;
}

@layer components {
  .disabled-day {
    /* pointer-events: none; */
    cursor: default !important;
    @apply bg-zinc-500;
  }
}

@layer components {
  .selected-day {
    @apply scale-110 bg-pink-500 hover:bg-pink-700 focus:outline-none focus:ring-2 focus:ring-pink-400 focus:ring-opacity-75;
  }
}
