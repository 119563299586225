.left-pane {
    flex: 1 1 20%;
    background-color: #d9d9d9;
    min-height: 100vh;
}

li {
    list-style: none;
}

hr {
    width: 85%;
    margin-left: auto;
    margin-right: auto;
    border-width: 0.2rem;
    color: #7d7c7c;
}