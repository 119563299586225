* {
  /*box-sizing: border-box;*/
  /* outline: 3px solid limegreen !important; */
}

body {
  font-family: "Asap", sans-serif;
}

abbr {
  /* display: none; */
}

.react-calendar__tile abbr {
  display: none;
}

.cardLinks {
  all: unset;
  cursor: pointer;
}
